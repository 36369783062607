import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login';
import { AuthGuard, AdminAuthGuard, GroupAuthGuard } from './_helpers';
import { SettingsComponent } from './settings/settings.component';
import { PagesComponent } from './pages/pages.component';
import { TrackingComponent } from './tracking/tracking.component';
import { ForgotPasswordComponent } from './forgot-password';
import { PasswordResetComponent } from './password-reset/password-reset.component';
import { AnalyticsComponent } from './analytics/analytics.component';
import { DashboardComponent } from './dashboard';
import { ContactComponent, ContactInfoComponent } from './contact';
import { TwoWayComponent } from './two-way';
import { AutomationComponent } from './automation';
import { AdminComponent } from './admin';
import { GroupComponent } from './group';
import { ContactsComponent } from './contacts';
import { BillingComponent } from './settings/billing/billing.component';
import { AccountComponent, IntegrationsComponent, MediaComponent } from './settings';
import { DefaultsComponent } from './settings/defaults/defaults.component';
import { ComplianceComponent } from './settings/compliance/compliance.component';
import { KnowledgeBaseComponent } from './knowledge-base/knowledge-base.component';
import { KnowledgeBaseLoginComponent } from './knowledge-base/knowledge-base-login/knowledge-base-login.component';
import { ByoaComponent } from './byoa/byoa.component';
import { BroadcastComponent } from './broadcast/broadcast.component';
import { TemplatePickerComponent } from './broadcast/template-picker/template-picker.component';
import { PendingChangesGuard } from './_helpers/pending-changes.guard';
import { TestComponent } from './test/test.component';
import { BroadcastBuilderComponent } from './broadcast/broadcast-builder/broadcast-builder.component';
import { NotificationsComponent } from './settings/notifications/notifications.component';
import { BotComponent } from '@app/settings/bot/bot.component';
import { ContactEventsComponent } from './contact/events/contactevents.component';
import { AlertComponent } from './alert/alert.component';
import { ViewFormsComponent } from './forms/view-forms/view-forms.component';
import { CreateFormComponent } from './forms/create-form/create-form.component';
import { FormsComponent } from './forms/forms.component';
import { ViewFormResultsComponent } from './forms/view-form-results/view-form-results.component';
import { VLAAllConversationsComponent, VLAAutomationsComponent, VLAComponent, VLADashboardComponent, VLAKnowledgeBase, VLASingleConversationComponent } from './vla';

export const routes: Routes = [
    {
        path: 'settings',
        component: SettingsComponent,
        canActivate: [AuthGuard],
        children: [
        {
            path: '',
            pathMatch: 'full',
            redirectTo: 'account'
        },
        {
            path: 'billing',
            component: BillingComponent
        },
        {
            path: 'account',
            component: AccountComponent
        },
        {
            path: 'notifications',
            component: NotificationsComponent
        },
        {
            path: 'defaults',
            component: DefaultsComponent
        },
        {
            path: 'compliance',
            component: ComplianceComponent
        },
        {
            path: 'integrations',
            component: IntegrationsComponent
        },
        {
            path: 'media',
            component: MediaComponent
        },
        {
            path: 'bot',
            component: BotComponent,
        },
    ] },
    {
        path: 'vla',
        component: VLAComponent,
        canActivate: [AuthGuard],
        children: [
        {
            path: '',
            pathMatch: 'full',
            redirectTo: 'automations',
        },
        {
            path: 'automations',
            component: VLAAutomationsComponent,
        },
        {
            path: 'allconversations',
            component: VLAAllConversationsComponent,
            children: [
                {
                    path: '',
                    pathMatch: 'full',
                    redirectTo: 'all'
                },
                {
                    path: 'all',
                    component: VLAAllConversationsComponent,
                    data: { filter: 'all' }
                },
                {
                    path: 'active-handoff',
                    component: VLAAllConversationsComponent,
                    data: { filter: 'active-handoff' }
                },
                {
                    path: 'email',
                    component: VLAAllConversationsComponent,
                    data: { filter: 'email' }
                },
                {
                    path: 'phone',
                    component: VLAAllConversationsComponent,
                    data: { filter: 'phone' }
                },
                {
                    path: 'active',
                    component: VLAAllConversationsComponent,
                    data: { filter: 'active' }
                }
            ]
        },
        {
            path: 'dashboard',
            component: VLADashboardComponent,
        },
        {
            path: 'knowledgebase',
            component: VLAKnowledgeBase,
        },
        {
            path: 'singleconversation',
            component: VLASingleConversationComponent,
        },
    ] },
    { path: 'settings', component: SettingsComponent, canActivate: [AuthGuard] },
    { path: 'admin', component: AdminComponent, canActivate: [AdminAuthGuard] },
    { path: 'group', component: GroupComponent, canActivate: [GroupAuthGuard] },
    {
        path: 'automation',
        component: AutomationComponent,
        canActivate: [AuthGuard]
    },
    { path: 'login', component: LoginComponent },
    { path: 'pages', component: PagesComponent, canActivate: [AuthGuard]  },
    { path: 'tracking', component: TrackingComponent, canActivate: [AuthGuard]  },
    { path: 'forgot-password', component: ForgotPasswordComponent },
    { path: 'password-reset', component: PasswordResetComponent },
    { path: 'analytics', component: AnalyticsComponent, canActivate: [AuthGuard]  },
    { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard]  },
    { path: 'contacts', component: ContactsComponent, canActivate: [AuthGuard] },
    {
        path: 'contact',
        component: ContactComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: '',
                pathMatch: 'full',
                redirectTo: 'info'
            },
            {
                path: 'info',
                component: ContactInfoComponent,
            },
            {
                path: 'events',
                component: ContactEventsComponent,
            },
        ]
    },
    { path: 'messages', redirectTo: 'conversations', pathMatch: 'full', canActivate: [AuthGuard]  },
    { path: 'conversations', component: TwoWayComponent, canActivate: [AuthGuard]  },
    { path: 'blasts', component: BroadcastComponent, canActivate: [AuthGuard] },
    { path: 'blasts/builder', component: BroadcastBuilderComponent, canActivate: [AuthGuard], canDeactivate: [PendingChangesGuard] },
    { path: 'blasts/templates', component: TemplatePickerComponent, canActivate: [AuthGuard] },
    {
        path: 'knowledge-base',
        component: KnowledgeBaseComponent,
        canActivate: [AuthGuard],
        children: [
        {
            path: '',
            pathMatch: 'full',
            redirectTo: 'login'
        },
        {
            path: 'login',
            component: KnowledgeBaseLoginComponent
        }
    ] },
    { path: 'automation/byoa', component: ByoaComponent, canActivate: [AuthGuard], canDeactivate: [PendingChangesGuard] },
    { path: 'alert', component: AlertComponent, canActivate: [AuthGuard], canDeactivate: [PendingChangesGuard] },
    { 
        path: 'forms', 
        component: FormsComponent,
        children: [
            {
                path: '',
                pathMatch: 'full',
                redirectTo: 'create'
            },
            {
                path: 'create',
                component: CreateFormComponent,
            },
            {
                path: ':id/edit',
                component: CreateFormComponent,
            },
            {
                path: 'view',
                component: ViewFormsComponent,
            },
            {
                path: ':id/results',
                component: ViewFormResultsComponent,
            }
        ] 
    },
    { path: 'test', component: TestComponent, canActivate: [AdminAuthGuard] },
    // otherwise redirect to home
    { path: '**', redirectTo: 'dashboard' }
];

export const appRoutingModule = RouterModule.forRoot(routes, {useHash: true, scrollPositionRestoration: 'top'});
