<div class="page-title">
  <h2>VLA Conversations</h2>
</div>
<ng-container *ngIf="(!treeNodes.length && !dateRange.length && !activeQuery.query) || firstLoad; else conversationsTable">
  <div class="row">
    <p class="text-center full-width margin-top-48">
      <img src="/assets/no-messaging.png" alt="No messaging" />
    </p>
    <h3 class="text-center full-width margin-top-48">You do not have any messages. <br /> Check back soon!</h3>
  </div>
</ng-container>

<ng-template #conversationsTable>
  <div class="card mt-4">
    <div class="card-body">
      <div class="overflow-auto">
        <p class="table-row-indicator pull-left" *ngIf="totalRecords > 0">
          <strong>Showing {{ pagination.first }} to {{ pagination.last }} of {{ totalRecords }} rows</strong>
        </p>
      </div>
      <p-treeTable
        [value]="treeNodes"
        styleClass="ui-table-vla-conversations"
        [paginator]="true"
        [rows]="25"
        [totalRecords]="totalRecords"
        [lazy]="true"
        (onLazyLoad)="paginate($event)"
        [rowsPerPageOptions]="[25, 50, 100, 250]"
      >
        <ng-template pTemplate="header">
          <tr class="ui-table-vla-conversation-header-row">
            <th>Contact Name</th>
            <th>Status</th>
            <th>Channel</th>
            <th>Last Updated</th>
            <th>Message Preview</th>
            <th>Actions</th>
          </tr>
          <tr class="ui-table-vla-conversation-header-row">
            <th>
              <input
                pInputText
                type="text"
                placeholder="Filter by name"
                class="w-100"
                [(ngModel)]="formNameSearchText"
                (ngModelChange)="debouncedSearch(formNameSearchText, 'name')"
              />
            </th>
            <th></th>
            <th></th>
            <th>
              <p-calendar
                #updatedCalendar
                selectionMode="range"
                (onSelect)="onDateSelect($event)"
                (onClearClick)="onDateClear($event)"
                [showButtonBar]="true"
                placeholder="Date"
                [readonlyInput]="true"
                dateFormat="mm-dd-yy"
                [style]="{ 'width': '100%' }"
                styleClass="ui-column-filter" 
                [inputStyle]="{ 'width': '100%' }"
              ></p-calendar>
            </th>
            <th></th>
            <th></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowNode let-rowData="rowData">
          <tr class="conversations-table" [class]="{'child': rowData.child}">
            <td>
              <p-treeTableToggler [rowNode]="rowNode"></p-treeTableToggler>
              {{ rowData.name }}
            </td>
            <td>
              <div *ngIf="!rowData.status" class="ai-status engaged"></div>
              <div *ngIf="rowData.status" class="ai-status disabled"></div>
            </td>
            <td class="text-center">
              <i *ngIf="rowData.channel.includes('email')" class="fa-regular fa-envelope"></i>
              <i *ngIf="rowData.channel.includes('call')" class="fa-regular fa-phone mx-2"></i>
            </td>
            <td>{{ rowData.lastUpdated | date: 'short' }}</td>
            <td [innerHTML]="rowData.messagePreview"></td>
            <td class="text-center">
              <i
                *ngIf="!rowNode.node.children"
                class="fa-regular fa-eye clickable"
                (click)="loadSingleConversation(rowData.id, rowData.name)"
              ></i>
            </td>
          </tr>
        </ng-template>
      </p-treeTable>
    </div>
  </div>
</ng-template>
